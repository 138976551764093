/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import viewport from 'helper/viewport';

import MobileMenu from 'modules/MobileMenu';
import StickyHeader from 'modules/StickyHeader';
import FlowingColumns from 'modules/FlowingColumns';
import ExpandableBox from 'modules/ExpandableBox';
import FooterAccordion from 'modules/FooterAccordion';
import EqualizeHeightFormRows from 'modules/EqualizeHeightFormRows';
import ContactFilter from 'modules/ContactFilter';
import 'imports-loader?$=jquery,jQuery=jquery!slick-carousel';
import SliderSlick from 'modules/SliderSlick';
import 'imports-loader?$=jquery,jQuery=jquery!shariff/dist/shariff.min';
import LazyLoad from "vanilla-lazyload";
import { getCLS } from 'web-vitals';

getCLS(console.log, true);
var lazyLoad = new LazyLoad();

$(document).ready(function () {
    handleLoginLink();

    lazyLoad.update();

    var $body = $('html, body'),
        $wrap = $('#page');
    window.scrollTop = null;

    var isIos = function () {
        return (navigator.platform.indexOf("iPhone") != -1) || (navigator.platform.indexOf("iPad") != -1);
    };

    $.lockBody = function () {
        if (!isIos()) {
            return;
        }

        if (window.pageYOffset) {
            window.scrollTop = window.pageYOffset;

            $wrap.css({
                top: -(scrollTop),
            });
        }

        $body.css({
            height: "100%",
            width: '100%',
            overflow: 'hidden',
            position: 'fixed'
        });
    };

    $.unlockBody = function () {
        if (!isIos()) {
            return;
        }

        $body.css({
            height: '',
            overflow: 'auto',
            position: 'relative'
        });

        $wrap.css({
            top: '',
        });

        window.setTimeout(function () {
            window.scrollTop = null;
        }, 0);
    };


    new MobileMenu();
    new StickyHeader($('#header').first());

    $(".flowing-col-grid").each(function () {
        var $element = $(this);
        new FlowingColumns($element);
    });

    $(".box--expandable").each(function () {
        var $element = $(this);
        new ExpandableBox($element);
    });

    $(".euqalize-form-rows").each(function () {
        var $element = $(this);
        var $targets = $element.find('.col');
        new EqualizeHeightFormRows($targets);
    });

    $(".footer-block").each(function () {
        var $element = $(this);
        new FooterAccordion($element);
    });

    initClickon();

    loadProductsWithRatings();

    $("[data-trigger-clickon]").hover(function (event) {
        $(this).css('cursor', 'pointer');
    });

    new ContactFilter();

    initSlickSlider();

    viewport();

    saveUTMParamsToCookie();

    notifyPlenigoOrder();

    window.addEventListenerForVendorConsent(488, renderDynamicOpinaryChapter);

    document.addEventListener('loadProductsWithRatings', function () {
        loadProductsWithRatings();
        $('body').removeClass('modal-open');
        $('#modal .modal').hide();
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#productList").offset().top - 175
        }, 1000);
    });

    authenticateSSOUser();
});

var notifyPlenigoOrder = function () {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);

    if (urlParams.get("order")) {
        let referenceId = $("[data-reference-id]").first().attr('data-reference-id');
        if (!referenceId) { return false; }

        $.get("/" + referenceId + "/purchased_ratings_modal", function (data) {
            $("#modal").html(data);
            $('body').addClass('modal-open');
            $('#modal .modal').fadeIn(400);
        });
    }
}

var initClickon = function ($parent = $(document)) {
    $parent.find("[data-trigger-clickon]").click(function (event) {
        var $target = $(this);
        var $link;

        if ($target.data("trigger-clickon") === '') {
            $link = $(this);
        } else {
            $link = $target.find($target.data("trigger-clickon"));
        }

        var data = {
            'event': 'trigger-clickon',
            'eventCatecory': 'Kiosk CTA und Teaser',
            'gtm.elementUrl': $link.attr("href")
        };

        if ($link.data("event-label")) {
            data['eventLabel'] = $link.data("event-label");
        }

        window.dataLayer.push(data);

        if ($target.data("trigger-clickon") === '') return;

        event.preventDefault();

        var win = window.open($link.attr("href"), '_blank');
        win.opener = null;

        if (win) {
            win.focus();
        } else {
            window.location.href = $link.attr("href");
        }
    })
};

var initSlickSlider = function () {
    $('#productList').show();

    // Enable correct tracking for product-box slide events
    window.dataLayer = window.dataLayer || [];

    function produktBoxTracking(eventAction, eventLabel) {
        console.log(eventAction + ' : ' + eventLabel);
        window.dataLayer.push({
            'event': 'Produktbox',
            'eventCatecory': 'Produktbox',
            'eventAction': eventAction,
            'eventLabel': eventLabel
        });
    }
    var slideStatus = '';
    var $productBoxSlider = $('.productList-body');
    $productBoxSlider.on('init', function (event, slick) {
        console.log("INIT SLICK-SLIDER");
    });
    $productBoxSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        slideStatus = (currentSlide + 1) + ' : ' + (nextSlide + 1);
    });
    $productBoxSlider.on('swipe', function (event, slick, direction) {
        produktBoxTracking('swipe', direction);
    });
    $productBoxSlider.on('click', function (e) {
        if ($(e.target).is('.pagination > li a')) {
            produktBoxTracking('pagina', slideStatus);
        } else if ($(e.target).is('.slick-prev')) {
            produktBoxTracking('arrow', 'prev');
        } else if ($(e.target).is('.slick-next')) {
            produktBoxTracking('arrow', 'next');
        }
    });

    // INIT slider slick
    var productCount = $('.product').length;
    var $productClones = $('.productList-body').find('.product').clone(true, true);
    $('.slider-container').each(function () {
        var $element = $(this);
        new SliderSlick($element);
    });

    // Do custom pagination for slider slick
    var renderPaginationLink = function (slick, index) {
        return '<a>' + (index + 1) + '</a>';
    };

    var renderInvisiblePaginationItem = function (slick, index) {
        return '<li role="presentation" style="display:none">' + renderPaginationLink(slick, index) + '</li>';
    };

    var renderPaginationItem = function (slick, index, currentSlide) {
        return '<li role="presentation"' + ((currentSlide === index) ? ' class="slick-active">' : '>') +
            renderPaginationLink(slick, index) + '</li>';
    };

    var renderPagination = function (slick) {
        var currentSlide = slick.currentSlide;
        var totalSlides = slick.slideCount;
        var distanceFromLastPage = totalSlides - currentSlide;
        var htmlOutput = '';

        if (totalSlides > 7 && currentSlide > 3) htmlOutput += '...';

        if (totalSlides > 7 && currentSlide > 2 && distanceFromLastPage > 4) {

            for (var i = 0; i < currentSlide - 3; i++) htmlOutput += renderInvisiblePaginationItem(slick, i);
            for (var i = -3; i <= 3; i++) htmlOutput += renderPaginationItem(slick, currentSlide + i, currentSlide);
            for (var i = currentSlide + 4; i < totalSlides; i++) htmlOutput += renderInvisiblePaginationItem(slick, i);

        } else if (totalSlides <= 7) {

            for (var i = 0; i < totalSlides; i++) htmlOutput += renderPaginationItem(slick, i, currentSlide);

        } else if (totalSlides > 7 && currentSlide <= 2) {

            for (var i = 0; i < 7; i++) htmlOutput += renderPaginationItem(slick, i, currentSlide);
            for (var i = 7; i < totalSlides; i++) htmlOutput += renderInvisiblePaginationItem(slick, i);

        } else if (totalSlides > 7 && distanceFromLastPage <= 4) {

            for (var i = 0; i < totalSlides - 7; i++) htmlOutput += renderInvisiblePaginationItem(slick, i);
            for (var i = 6; i >= 0; i--) htmlOutput += renderPaginationItem(slick, totalSlides - i - 1, currentSlide);

        }

        if (totalSlides > 7 && distanceFromLastPage > 4) htmlOutput += '...';

        return htmlOutput;
    };

    // Init Slick-Slider Page-Counter
    $('.productList-body').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        var i = currentSlide ? currentSlide : 0;
        var productsPerPage = slick.options['rows'] * slick.options['slidesPerRow'];
        var firstProduct = i * productsPerPage + 1;
        var lastProduct = ((i + 1) * productsPerPage > productCount) ? productCount : (i + 1) * productsPerPage;

        var counterText = 'Kein Produkt gefunden';

        if (productCount !== 0) {
            counterText = '(' + firstProduct + ' - ' + lastProduct + ') von ' + productCount;
        }

        $('.pagination-counter').text(counterText);

        var pagination = $(this).find('.pagination');
        pagination.html(renderPagination(slick));

        slick.initDotEvents();
    });

    // Create Product-List Slider with Slick Slider Gem
    var sliderConfigTiles = {
        infinite: true,
        slidesPerRow: 4,
        touchThreshold: 25,
        rows: 3,
        dots: true,
        dotsClass: 'pagination',
        customPaging: renderPaginationLink,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesPerRow: 2,
            }
        }],
    };
    var sliderConfigCompact = {
        infinite: true,
        slidesPerRow: 1,
        touchThreshold: 25,
        rows: 20,
        dots: true,
        dotsClass: 'pagination',
        customPaging: renderPaginationLink,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        responsive: [{
            breakpoint: 768,
            settings: {
                rows: 12,
            }
        }],
    };
    $('.productList-tiles > .productList-body').slick(sliderConfigTiles);
    $('.productList-compact > .productList-body').slick(sliderConfigCompact);

    $('#productList .slick-arrow').clone(true).appendTo('.productList-body').addClass('slick-arrow-top');

    // FILTERS
    $.expr[":"].containsIN = $.expr.createPseudo(function (arg) {
        return function (elem) {
            return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
        };
    });

    $('.productList-filter').on('keyup change', function () {
        setTimeout(() => {
            var filterText = $('.productList-filter [name="text"]').val();
            var filterGrade = $('.productList-filter #grades option:selected').val();
            var $slider = $('.productList-body');

            $slider.slick('unslick');
            $slider.empty();

            var $filteredProducts = $productClones;

            if (filterGrade && filterGrade !== '') {
                $filteredProducts = $filteredProducts.filter('[data-grade="' + filterGrade + '"]');
            }

            if (filterText !== '') {
                $filteredProducts = $filteredProducts.filter('.product:containsIN("' + filterText + '")');
            }

            productCount = $filteredProducts.length;
            $slider.html($filteredProducts);
            lazyLoad.update();

            $('.productList-tiles > .productList-body').slick(sliderConfigTiles);
            $('.productList-compact > .productList-body').slick(sliderConfigCompact);

            $('#productList .slick-arrow').clone(true).appendTo('.productList-body').addClass('slick-arrow-top');
        }, 400);
    });

    // Events for product search tracking
    $('.productList-filter #grades').on('change', function () {
        var filterGrade = $('.productList-filter #grades option:selected').val();
        window.dataLayer.push({
            'event': 'Produktbox',
            'eventCatecory': 'Produktbox',
            'eventAction': 'productgrades',
            'eventLabel': filterGrade
        });
    });

    var timeout = null;
    $('.productList-filter #text').on('keyup', function () {
        clearTimeout(timeout);
        var filterText = $('.productList-filter [name="text"]').val();
        timeout = setTimeout(function () {
            window.dataLayer.push({
                'event': 'Produktbox',
                'eventCatecory': 'Produktbox',
                'eventAction': 'productsearch',
                'eventLabel': filterText
            });
        }, 2000);
    });

    // additional classes for accordion
    $('.collapsible-panels__anchor').on('click', function (event) {
        event.preventDefault();
        let panel = $(this).parents('.panel').first();

        if ($(this).hasClass('collapsed')) {
            $(this).removeClass('collapsed').addClass('not-collapsed');
            panel.children('.panel-collapse').removeClass('collapse');
            return;
        }
        $(this).removeClass('not-collapsed').addClass('collapsed');
        panel.children('.panel-collapse').addClass('collapse');
    });

    $('body').delegate('.modal-close', 'click', function (event) {
        event.preventDefault();
        $.unlockBody();

        $(this).parents('.modal').fadeOut(400, function () {
            $('body').removeClass('modal-open');
        });
    });

    $('body').delegate('.modal', 'click', function (event) {
        if ($(event.target).hasClass('modal')) {
            event.preventDefault();
            $.unlockBody();

            $(this).fadeOut(400, function () {
                $('body').removeClass('modal-open');
            });
        }
    });
};

var loadProductsWithRatings = function () {
    let referenceId = $("[data-reference-id]").first().attr('data-reference-id');
    if (!referenceId) { return false; }

    $.ajax({
        url: '/' + referenceId + '/products',
        success: function (result) {
            $('.featured-box').remove();
            $('.button-uk').remove();
            $('.pdf-download').show();

            $('.slick-initialized').each(function () {
                var $element = $(this);
                $element.slick('unslick');
            });

            $('#productList').empty();
            $('#productList').replaceWith(result);

            initSlickSlider();
            lazyLoad.update();
        },
        error: function (xhr) {
            if (xhr.status == 403) {
                $('.purchase-ratings .authenticate-action').remove();
            }
        }
    });
};

var saveUTMParamsToCookie = function () {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var utm = {};

    utm.medium = urlParams.get("utm_medium");
    utm.source = urlParams.get("utm_source");
    utm.campaign = urlParams.get("utm_campaign");
    utm.content = urlParams.get("utm_content");
    utm.term = urlParams.get("utm_term");

    Object.keys(utm).forEach(function (k) {
        return utm[k] == null && delete utm[k];
    });

    Object.keys(utm).forEach(function (k) {
        return (utm[k] = encodeURIComponent(utm[k] + "")
            .replace(/%20/g, "+")
            .slice(0, 100));
    });

    if (Object.entries(utm).length !== 0) {
        document.cookie = 'utm=' + encodeURIComponent(JSON.stringify(utm)) + ';path=/;';
    }
}

var renderDynamicOpinaryChapter = function () {
    var articleMetaData = document.getElementById('survey-meta-data');
    var articleMetaDataContent = JSON.parse(articleMetaData.innerHTML);

    if (!articleMetaDataContent) {
        return;
    }

    $.get("/api/surveys", function (data) {
        var surveys = data.filter(function (survey) {
            return survey[articleMetaDataContent.articleClass.toLowerCase()] == true &&
                (survey.sections.length == 0 || survey.sections.find(function (section) {
                    return section.slug == articleMetaDataContent.sectionSlug;
                })
                );
        });

        if (surveys instanceof Array) {
            var survey = surveys[Math.floor(Math.random() * surveys.length)];
        } else {
            var survey = surveys;
        }

        if (survey) {
            var surveyContainer = document.createElement('div');
            surveyContainer.setAttribute('class', 'opinary-widget-embed');
            surveyContainer.setAttribute('data-poll', survey.slug);
            surveyContainer.setAttribute('data-customer', 'oekotest');

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '//widgets.opinary.com/embed.js';
            script.async = true;

            var h2Elements = document.getElementsByTagName('h2');
            if (h2Elements.length >= 2) {
                h2Elements[1].parentNode.insertBefore(surveyContainer, h2Elements[1]);
                h2Elements[1].parentNode.insertBefore(script, h2Elements[1]);
            }
        }
    }
    );

};

var handleLoginLink = function () {
    if (document.cookie.indexOf('userLoggedIn=true') > -1) {
        $('.login-link').hide();
        $('.my-account-link').show();
    } else {
        $('.login-link').show();
        $('.my-account-link').hide();
    }
};

var authenticateSSOUser = function () {
    $.ajax({
        type: 'GET',
        xhrFields: {
            withCredentials: true
        },
        url: "/sessions/authenticate",
        success: function (data, textStatus, jqXHR) {
            var sessionCreateURL = data.url;

            $.ajax({
                url: sessionCreateURL,
                type: 'GET',
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                success: function (data, textStatus, jqXHR) {
                    window.tp && window.tp.push(["setExternalJWT", data.access_token]);

                    document.cookie = "userLoggedIn=true; path=/;";

                    handleLoginLink();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    document.cookie = "userLoggedIn=false; path=/;";

                    handleLoginLink();
                },
                complete: function () {
                    window.loadPianoScripts();
                    window.ssoCompleted = true;
                }
            });
        },
        error: function (jqXHR, textStatus, errorThrown) {
            document.cookie = "userLoggedIn=false; path=/;";

            handleLoginLink();

            window.loadPianoScripts();
            window.ssoCompleted = true;
        }
    });
};

window.addEventListenerForVendorConsent = function (vendorId, callback) {
    window.__tcfapi("addEventListener", 2, function (tcData, success) {
        if (!tcData || !success) {
            return;
        }

        if (
            tcData.eventStatus !== "useractioncomplete" &&
            tcData.eventStatus !== "tcloaded"
        ) {
            return;
        }

        if (!tcData.vendor || !tcData.vendor.consents) {
            return;
        }

        if (tcData.vendor.consents[vendorId]) {
            callback();
        }
    });
};

$(window).resize(viewport);
